<template>
	<div>
		<div
			v-if="!loggedIn"
			class="flex flex-col justify-center min-h-full py-2 sm:px-6 lg:px-8"
		>
			<div
				v-if="!hideH2"
				class="sm:mx-auto sm:w-full sm:max-w-md"
			>
				<h2 class="mt-6 text-3xl font-semibold tracking-tight text-center text-gray-900">
					{{ t('login.h2') }}
				</h2>
			</div>

			<div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
				<div class="px-4 py-8 bg-white sm:px-10">
					<form @submit.prevent="submit">
						<div class="space-y-2">
							<FormTextInput
								:id="USERNAME"
								v-model="username"
								:label="t('login.username')"
								:autocomplete="USERNAME"
								:error-message="t('username-error-message')"
								:input-type="TEXT"
								:state="inputFieldStateValidator(username, TEXT)"
								placeholder="johndoe@123.xyz"
								floating-label
								required
							/>
							<FormTextInput
								:id="PASSWORD"
								v-model="password"
								:label="t('login.password')"
								:autocomplete="PASSWORD"
								:input-type="PASSWORD"
								placeholder="wdSkD@123!ds"
								floating-label
								required
								@keyup="passwordKeyup"
							/>
						</div>
						<div class="pt-4 space-y-12">
							<div class="flex items-center justify-between">
								<div class="flex items-center">
								<!--<input
									id="remember-me"
									name="remember-me"
									type="checkbox"
									class="w-4 h-4 border-gray-300 rounded text-mx-orange focus:ring-mx-orange"
								>
								<label
									for="remember-me"
									class="block ml-2 text-sm text-gray-900"
								>
									Remember me
								</label>-->
								</div>

								<div class="text-sm">
									<NuxtLink
										to="/forgot-password"
										class="font-medium text-mx-orange hover:text-mx-orange"
									>
										{{ t('login.forgot') }}
									</NuxtLink>
								</div>
							</div>

							<FormButton
								:button-text="submitButtonText"
								submit
								:disabled="false"
								:is-loading="loading"
								class="uppercase"
							/>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div
			v-else
			class="flex pt-14"
		>
			<NuxtLink
				:to="`${accountLinkPage || accountUrl}`"
				class="px-6 py-4 m-auto text-sm font-semibold text-white uppercase rounded-lg bg-mx-orange hover:bg-mx-orange-muted"
			>
				{{ accountLinkText }}
			</NuxtLink>
		</div>
		<ModalTwoFactorAuthentication
			:open-modal="isModalOpen(TWO_FACTOR_MODAL_ID)"
			@cancel="closeModal"
			@confirm="handle2FAVerification"
		/>
	</div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { inputFieldStateValidator } from '@/validators/text-inputs.js'
import { PASSWORD, TEXT } from '@/constants/form/text-inputs/types.js'
import { useAuth } from '@/composables/useAuth'
import { useModal } from '@/composables/useModal'
import { useLogin } from '@/composables/useLogin'

const {
	loading,
	login,
	TWO_FACTOR_MODAL_ID
} = useLogin()

const { closeModal, isModalOpen } = useModal()

const { loggedIn, accountUrl } = useAuth()

const { t } = useI18n({
	useScope: 'global'
})

const props = defineProps({
	hideH2: {
		type: Boolean,
		default: false
	},
	customSubmitText: {
		type: String,
		default: ''
	},
	customAccountLinkText: {
		type: String,
		default: ''
	},
	accountLinkPage: {
		type: String,
		default: ''
	}
})
const { hideH2, customSubmitText, customAccountLinkText, accountLinkPage } = toRefs(props)
const submitButtonText = computed(() => {
	return customSubmitText.value || t('login.button-text')
})

const accountLinkText = computed(() => {
	return customAccountLinkText.value || 'View your account'
})

const USERNAME = 'username'

const username = ref('')
const password = ref('')

const passwordKeyup = (event: KeyboardEvent) => {
	if (event.key === 'Enter') {
		submit()
	}
}

const submit = async () => {
	const formData = {
		email: username.value,
		password: password.value
	}

	await login(formData.email, formData.password)
}

const handle2FAVerification = async (otp: number) => {
	await login(username.value, password.value, otp)
}
</script>
