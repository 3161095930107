<template>
	<Modal
		:id="id"
		:is-open="openModal"
		@on-confirm="$emit('confirm')"
		@on-close="$emit('cancel')"
	>
		<template #title>
			<h2 class="w-full text-3xl font-bold text-center">
				{{ t('two-factor-authentication-modal.title') }}
			</h2>
		</template>
		<template #body>
			<p class="w-full max-w-sm py-2 mx-auto text-center text-mx-gray-500">
				{{ t('two-factor-authentication-modal.description') }}
			</p>
			<div class="flex justify-center gap-2 py-4">
				<label
					v-for="(_n, index) in code"
					:key="index"
					:for="`input-${index}`"
					class="sr-only"
				>
					{{ t(`two-factor-authentication-modal.labels.code-${index}`) }}
				</label>
				<input
					v-for="(_n, index) in code"
					:id="`input-${index}`"
					:key="index"
					v-model="code[index]"
					type="number"
					pattern="\d*"
					maxlength="1"
					class="w-12 h-20 text-4xl text-center border-2 rounded outline-none appearance-none md:w-16 focus:shadow shadow-transparent focus:border-mx-orange focus:shadow-mx-orange-muted"
					autocomplete="one-time-code"
					@input="handleInput"
					@keypress="isNumber"
					@keydown.delete="handleDelete"
					@paste="onPaste"
				>
			</div>
		</template>
		<template #footer>
			<div class="flex justify-center w-full px-4 mt-4 space-x-2">
				<FormButton
					button-classes="uppercase font-bold"
					variant="secondary"
					button-width="w-32"
					:button-text="t('two-factor-authentication-modal.buttons.cancel')"
					@click="$emit('cancel')"
				/>
				<FormButton
					button-classes="uppercase font-bold"
					button-width="w-32"
					:button-text="t('two-factor-authentication-modal.buttons.confirm')"
					:variant="isDisabled ? 'disabled' : 'primary'"
					:disabled="isDisabled"
					:is-loading="isLoading"
					@click="handleVerify"
				/>
			</div>
		</template>
	</Modal>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
const { t } = useI18n({
	useScope: 'global'
})

const props = defineProps({
	id: {
		type: String,
		default: 'TwoFactorModal'
	},
	openModal: {
		type: Boolean,
		default: false
	},
	loginCredentials: {
		type: Object,
		default: () => ({
			email: '',
			password: ''
		})
	}
})

const { openModal } = toRefs(props)

const emit = defineEmits([ 'confirm', 'cancel', 'resend' ])

let dataFromPaste: string[] | undefined

const code = ref<string[]>(Array(6))

const otp = computed(() => {
	return code.value.join('')
})

const isDisabled = computed(() => {
	return otp.value.length !== 6
})

const isLoading = computed(() => {
	return false
})

const keysAllowed: string[] = [ '0', '1', '2', '3', '4', '5', '6', '7', '8', '9' ]

const isNumber = (event: Event) => {
	(event.currentTarget as HTMLInputElement).value = ''
	const keyPressed: string = (event as KeyboardEvent).key
	if (!keysAllowed.includes(keyPressed)) {
		event.preventDefault()
	}
}

const handleDelete = (event: Event) => {
	const value = (event.target as HTMLInputElement).value
	const currentActiveElement = event.target as HTMLInputElement
	if (!value) {
		(currentActiveElement.previousElementSibling as HTMLElement)?.focus()
	}
}

const onPaste = (event: Event) => {
	dataFromPaste = (event as ClipboardEvent).clipboardData?.getData('text').trim().split('')
	if (dataFromPaste) {
		for (const num of dataFromPaste) {
			if (!keysAllowed.includes(num)) { event.preventDefault() }
		}
	}
}

const handleInput = (event: Event) => {
	const inputType = (event as InputEvent).inputType
	let currentActiveElement = event.target as HTMLInputElement

	if (inputType === 'insertText') {
		(currentActiveElement.nextElementSibling as HTMLElement)?.focus()
	}

	if (inputType === 'insertFromPaste' && dataFromPaste) {
		for (const num of dataFromPaste) {
			const id: number = parseInt(currentActiveElement.id.split('-')[1])

			currentActiveElement.value = num
			code.value[id] = num

			if (currentActiveElement.nextElementSibling) {
				currentActiveElement = currentActiveElement.nextElementSibling as HTMLInputElement
			}

			(currentActiveElement.nextElementSibling as HTMLElement)?.focus()
		}
	}
}

const handleVerify = () => {
	if (isDisabled.value) { return }

	emit('confirm', parseInt(otp.value))
}
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
